<template>
  <div style="white-space: nowrap">
    <b-overlay :show="isLoading || isModalLoading" class="d-flex" style="gap: 8px">
      <!-- Seitenleiste -->
      <div>
        <div class="card p-2" style="gap: 12px; align-items: center; width: 100%">
          <div
            v-for="(saison, i) in sortSaisons"
            :key="'saison-' + saison.id"
            class="p-3 rounded"
            style="cursor: pointer; width: 100%"
            :class="activeSaisonGueltigAb === saison.gueltigAb ? 'bg-primary text-white' : 'bg-secondary'"
            @click="setActiveSaisonGueltigAb(saison.gueltigAb)"
          >
            <div class="d-flex">
              {{ `${i + 1}. Saison` }}
            </div>

            <div>{{ 'Ab ' + saison.formattedGueltigAb }}</div>
          </div>
          <div class="w-100" style="display: grid; grid-template-columns: repeat(5, 1fr)">
            <b-button
              class="btn-xs btn py-1 px-2 justify-content-center align-items-center d-flex"
              v-b-tooltip.hover
              :title="'Die Passdaten werden pro Reise und pro Saison angelegt und gepflegt. Die Konfiguration gibt vor, welche Passdaten und Dokumente für die Reise bzw. den Flug notwendig sind.'"
            >
              <i class="fas fa-info m-0 p-0"></i>
            </b-button>
            <div style="grid-column-start: 3" class="bg-primary plus-sign" v-b-modal.modalSaisonHinzufuegen>
              +
            </div>
          </div>
        </div>
      </div>
      <!-- Show active season -->
      <div class="d-flex w-100" style="flex-direction: column; position: relative">
        <span
          class="d-flex ml-auto rounded mr-4"
          style="gap: 12px; z-index: 99; padding: 12px; position: fixed; right: 0; top: 130px; float: left"
        >
          <b-button
            @click="saveChanges"
            :disabled="disableSichernButton"
            :variant="disableSichernButton ? '' : 'primary'"
          >
            Speichern
          </b-button>
          <b-button v-b-modal.modalSaisonLöschen variant="secondary" v-if="canRemoveSaison">
            Saison löschen
          </b-button>
        </span>

        <div class="p-6 mb-2 card" style="min-width: 900px">
          <h4 class="w-25 mb-4">Passdaten-Konfiguration</h4>

          <div class="d-flex px-4 mb-2" style="gap: 16px">
            <div>
              <b-button variant="success" class="mr-2">DOM</b-button>
              <b-button variant="warning" disabled>FOM</b-button>
            </div>
          </div>

          <!-- die einzelnen sections der Daten -->
          <div v-for="(section, index) in editedSaison" :key="index">
            <h5 :class="{ 'd-flex px-4 mt-5': section.title !== '' }">{{ section.title }}</h5>
            <div class="d-flex px-4">
              <div
                v-for="(data, idx) in section.data"
                :key="idx"
                class="px-4 mt-2"
                style="display: flex; flex-direction: column; align-items: center"
              >
                <div>{{ data.name }}</div>

                <div class="mt-1 mb-3 d-flex justify-content-evenly" v-if="data.type === 'checkbox'">
                  <b-form-checkbox
                    size="lg"
                    v-model="data.valueDOMEdited"
                    class="custom-checkbox-DOM mr-3 mb-3"
                    :class="{ changed: data.valueDOM === data.valueDOMEdited }"
                    :disabled="!DOMAllowedToEdit"
                  />
                  <b-form-checkbox
                    v-if="data.valueFOM != null"
                    size="lg"
                    v-model="data.valueFOMEdited"
                    class="custom-checkbox-FOM"
                    :class="{ changed: data.valueFOM === data.valueFOMEdited }"
                    :disabled="!FOMAllowedToEdit"
                  />
                </div>

                <div v-else>
                  <b-form-input
                    v-model="data.valueEdited"
                    size="sm"
                    :class="{ changed: data.value === data.valueEdited }"
                    :disabled="!DOMAllowedToEdit"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

    <b-modal id="modalSaisonHinzufuegen" centered title="Saison hinzufügen" size="sm">
      <b-overlay :show="isModalLoading">
        <b-form-group label="Gültig ab">
          <DatePicker :lang="langConfig" type="date" format="DD.MM.YYYY" v-model="gueltigAb"></DatePicker>
        </b-form-group>
      </b-overlay>
      <template #modal-footer="{ hide }" class="pl-1">
        <b-button class="mr-4" @click="addNewSaison(hide)" :disabled="gueltigAb === null" variant="success">
          Erstellen
        </b-button>
        <b-button @click="resetAndCloseModal(hide)">Abbrechen</b-button>
      </template>
    </b-modal>

    <b-modal id="modalSaisonLöschen" centered title="Saison löschen" size="sm">
      <b-overlay :show="isModalLoading">
        <p>Möchtest du die Saison {{ activeSaisonGueltigAbFormatted }} wirklich löschen?</p>
      </b-overlay>
      <template #modal-footer="{ hide }" class="p-0 m-0">
        <b-button class="mr-2" @click="hide">Abbrechen</b-button>
        <b-button @click="deleteSaison(hide)" variant="danger">Löschen</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { isSameDay, compareDesc, format } from 'date-fns';
import apiService from '@/core/common/services/api.service';
import { langConfig, formatDateWithoutHours } from '@/core/common/helpers/utils.js';
import DatePicker from 'vue2-datepicker';
import { mapGetters } from 'vuex';

export default {
  props: {
    reise: { type: Object },
    konfigurationen: {
      type: Array,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      langConfig,
      gueltigAb: null,
      lastSaveChangedFields: [],
      preexistingFormDataMultipleReisetermin: null,

      saisons: [],
      activeSaisonGueltigAb: null,
      editedSaison: [
        {
          title: 'Personenbezogene Daten',
          data: [
            {
              name: 'Geburtsdatum',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null, // nur diese wird verändert
              valueFOM: null,
              valueFOMEdited: null, // nur diese wird verändert
            },
            {
              name: 'Passnummer',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
            {
              name: 'Passart',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
            {
              name: 'Nationalität',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
            {
              name: 'Geschlecht',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
            {
              name: 'Geburtsort',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
            {
              name: 'Geburtsland',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
          ],
        },
        {
          title: 'Passbezogene Daten',
          data: [
            {
              name: 'Ausstelldatum',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
            {
              name: 'Gültigkeitsdatum',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
            {
              name: 'Land',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
            {
              name: 'Ort',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
            {
              name: 'Passkopie',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
            {
              name: 'Passfarbkopie',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
            {
              name: 'Passfoto',
              type: 'checkbox',
              valueDOM: null,
              valueDOMEdited: null,
              valueFOM: null,
              valueFOMEdited: null,
            },
          ],
        },
        {
          title: 'Zusätzliche Infos',
          data: [
            { name: 'Versicherungsnachweis', valueDOM: null, valueDOMEdited: null, type: 'checkbox' },
            { name: 'Verzichtserklärung', valueDOM: null, valueDOMEdited: null, type: 'checkbox' },
            { name: 'LOI-Antrag', valueDOM: null, valueDOMEdited: null, type: 'checkbox' },
            { name: 'Referenznr.-Antrag', valueDOM: null, valueDOMEdited: null, type: 'checkbox' },
            {
              name: 'Zum Buchungszeitpunkt benötigt',
              valueDOM: null,
              valueDOMEdited: null,
              type: 'checkbox',
            },
          ],
        },
        {
          title: '',
          data: [
            {
              name: 'Weitergabe an CM',
              value: null,
              valueEdited: null,
              type: 'textField',
            },
            { name: 'Blank Kürzel', value: null, valueEdited: null, type: 'textField' },
            { name: 'Grund', value: null, valueEdited: null, type: 'textField' },
          ],
        },
      ],
      isLoading: null,
      isModalLoading: false,
    };
  },
  mounted() {
    this.activeSaisonGueltigAb = this.sortSaisons[0]?.gueltigAb;
    this.setEditedKonfiguration();
  },
  watch: {
    activeSaisonGueltigAb() {
      this.setEditedKonfiguration();
    },
  },
  computed: {
    ...mapGetters(['parsedRoles']),

    DOMAllowedToEdit() {
      return this.parsedRoles.includes('Admin') || this.parsedRoles.includes('Dom');
    },
    FOMAllowedToEdit() {
      return this.parsedRoles.includes('Admin') || this.parsedRoles.includes('Fom');
    },
    activeKonfiguration() {
      if (this.activeSaisonGueltigAb == null) {
        return null;
      }
      return this.konfigurationen.find(item => item.gueltigAb === this.activeSaisonGueltigAb);
    },
    sortSaisons() {
      return this.konfigurationen
        .map(item => {
          return { ...item, formattedGueltigAb: formatDateWithoutHours(item.gueltigAb) };
        })
        .sort((a, b) => compareDesc(new Date(a.gueltigAb), new Date(b.gueltigAb)));
    },
    activeSaisonGueltigAbFormatted() {
      return formatDateWithoutHours(this.activeSaisonGueltigAb);
    },
    changedFields() {
      return this.editedSaison.some(section =>
        section.data.some(data => {
          if (data.type === 'checkbox') {
            // Überprüft Änderungen in DOM und FOM Checkboxen
            return data.valueDOM !== data.valueDOMEdited || data.valueFOM !== data.valueFOMEdited;
          } else if (data.type === 'textField') {
            // Überprüft Änderungen in Textfeldern
            return data.value !== data.valueEdited;
          }
          return false;
        })
      );
    },
    disableSichernButton() {
      return !this.changedFields;
    },
    canRemoveSaison() {
      return (
        !isSameDay(new Date(this.activeSaisonGueltigAb), new Date('0001-01-01')) &&
        this.konfigurationen?.length !== 1
      );
    },
  },
  methods: {
    setEditedKonfiguration() {
      if (this.konfigurationen) {
        this.editedSaison = [
          {
            title: 'Personenbezogene Daten',
            data: [
              {
                name: 'Geburtsdatum',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.geburtsdatumRequired,
                valueDOMEdited: this.activeKonfiguration.geburtsdatumRequired,
                valueFOM: this.activeKonfiguration.geburtsdatumRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.geburtsdatumRequiredFlug,
              },
              {
                name: 'Passnummer',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.passNummerRequired,
                valueDOMEdited: this.activeKonfiguration.passNummerRequired,
                valueFOM: this.activeKonfiguration.passNummerRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.passNummerRequiredFlug,
              },
              {
                name: 'Passart',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.passartRequired,
                valueDOMEdited: this.activeKonfiguration.passartRequired,
                valueFOM: this.activeKonfiguration.passartRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.passartRequiredFlug,
              },
              {
                name: 'Nationalität',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.nationalitaetRequired,
                valueDOMEdited: this.activeKonfiguration.nationalitaetRequired,
                valueFOM: this.activeKonfiguration.nationalitaetRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.nationalitaetRequiredFlug,
              },
              {
                name: 'Geschlecht',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.geschlechtRequired,
                valueDOMEdited: this.activeKonfiguration.geschlechtRequired,
                valueFOM: this.activeKonfiguration.geschlechtRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.geschlechtRequiredFlug,
              },
              {
                name: 'Geburtsort',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.geburtsortRequired,
                valueDOMEdited: this.activeKonfiguration.geburtsortRequired,
                valueFOM: this.activeKonfiguration.geburtsortRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.geburtsortRequiredFlug,
              },
              {
                name: 'Geburtsland',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.geburtslandRequired,
                valueDOMEdited: this.activeKonfiguration.geburtslandRequired,
                valueFOM: this.activeKonfiguration.geburtslandRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.geburtslandRequiredFlug,
              },
            ],
          },
          {
            title: 'Passbezogene Daten',
            data: [
              {
                name: 'Ausstelldatum',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.passAusstellungsdatumRequired,
                valueDOMEdited: this.activeKonfiguration.passAusstellungsdatumRequired,
                valueFOM: this.activeKonfiguration.passAusstellungsdatumRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.passAusstellungsdatumRequiredFlug,
              },
              {
                name: 'Gültigkeitsdatum',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.passGueltigkeitsdatumRequired,
                valueDOMEdited: this.activeKonfiguration.passGueltigkeitsdatumRequired,
                valueFOM: this.activeKonfiguration.passGueltigkeitsdatumRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.passGueltigkeitsdatumRequiredFlug,
              },
              {
                name: 'Land',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.passLandRequired,
                valueDOMEdited: this.activeKonfiguration.passLandRequired,
                valueFOM: this.activeKonfiguration.passLandRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.passLandRequiredFlug,
              },
              {
                name: 'Ort',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.passOrtRequired,
                valueDOMEdited: this.activeKonfiguration.passOrtRequired,
                valueFOM: this.activeKonfiguration.passOrtRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.passOrtRequiredFlug,
              },
              {
                name: 'Passkopie',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.passkopieBwRequired,
                valueDOMEdited: this.activeKonfiguration.passkopieBwRequired,
                valueFOM: this.activeKonfiguration.passkopieBwRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.passkopieBwRequiredFlug,
              },
              {
                name: 'Passfarbkopie',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.passkopieColorRequired,
                valueDOMEdited: this.activeKonfiguration.passkopieColorRequired,
                valueFOM: this.activeKonfiguration.passkopieColorRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.passkopieColorRequiredFlug,
              },
              {
                name: 'Passfoto',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.passfotoRequired,
                valueDOMEdited: this.activeKonfiguration.passfotoRequired,
                valueFOM: this.activeKonfiguration.passfotoRequiredFlug,
                valueFOMEdited: this.activeKonfiguration.passfotoRequiredFlug,
              },
            ],
          },
          {
            title: 'Zusätzliche Infos',
            data: [
              {
                name: 'Versicherungsnachweis',
                valueDOM: this.activeKonfiguration.versicherungsnachweisRequired,
                valueDOMEdited: this.activeKonfiguration.versicherungsnachweisRequired,
                type: 'checkbox',
              },
              {
                name: 'Verzichtserklärung',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.verzichtserklaerungRequired,
                valueDOMEdited: this.activeKonfiguration.verzichtserklaerungRequired,
              },
              {
                name: 'LOI-Antrag',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.loiAntragRequired,
                valueDOMEdited: this.activeKonfiguration.loiAntragRequired,
              },
              {
                name: 'Referenznr.-Antrag',
                type: 'checkbox',
                valueDOM: this.activeKonfiguration.referenznummerAntragRequired,
                valueDOMEdited: this.activeKonfiguration.referenznummerAntragRequired,
              },
              {
                name: 'Zum Buchungszeitpunkt benötigt',
                valueDOM: this.activeKonfiguration.zumBuchungszeitpunktBenoetigt,
                valueDOMEdited: this.activeKonfiguration.zumBuchungszeitpunktBenoetigt,
                type: 'checkbox',
              },
            ],
          },
          {
            title: '',
            data: [
              {
                name: 'Weitergabe an CM',
                value: this.activeKonfiguration.weitergabeAnCm,
                valueEdited: this.activeKonfiguration.weitergabeAnCm,
                type: 'textField',
              },
              {
                name: 'Blank Kürzel',
                value: this.activeKonfiguration.blankKuerzel,
                valueEdited: this.activeKonfiguration.blankKuerzel,
                type: 'textField',
              },
              {
                name: 'Grund',
                value: this.activeKonfiguration.grund,
                valueEdited: this.activeKonfiguration.grund,
                type: 'textField',
              },
            ],
          },
        ];
      }
    },
    setActiveSaisonGueltigAb(gueltigAbDate) {
      this.activeSaisonGueltigAb = gueltigAbDate;
      this.setEditedKonfiguration();
    },
    saveChanges() {
      this.isLoading = true;
      const reisekuerzel = this.reise.reisekuerzel;
      const configId = this.activeKonfiguration.id; // Hole die ID der aktiven Konfiguration

      let params = {
        id: configId,
        gueltigAb: this.activeKonfiguration.gueltigAb,
      };

      // Fülle die Parameter für die Konfiguration mit den entsprechenden Werten
      this.editedSaison.forEach(section => {
        section.data.forEach(data => {
          if (data.type === 'checkbox') {
            // es wird so formattiert, wie es benötigt wird
            if (data.name === 'Geburtsdatum') {
              params.geburtsdatumRequired = data.valueDOMEdited;
              params.geburtsdatumRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Passnummer') {
              params.passNummerRequired = data.valueDOMEdited;
              params.passNummerRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Passart') {
              params.passartRequired = data.valueDOMEdited;
              params.passartRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Nationalität') {
              params.nationalitaetRequired = data.valueDOMEdited;
              params.nationalitaetRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Geschlecht') {
              params.geschlechtRequired = data.valueDOMEdited;
              params.geschlechtRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Geburtsort') {
              params.geburtsortRequired = data.valueDOMEdited;
              params.geburtsortRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Geburtsland') {
              params.geburtslandRequired = data.valueDOMEdited;
              params.geburtslandRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Ausstelldatum') {
              params.passAusstellungsdatumRequired = data.valueDOMEdited;
              params.passAusstellungsdatumRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Gültigkeitsdatum') {
              params.passGueltigkeitsdatumRequired = data.valueDOMEdited;
              params.passGueltigkeitsdatumRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Land') {
              params.passLandRequired = data.valueDOMEdited;
              params.passLandRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Ort') {
              params.passOrtRequired = data.valueDOMEdited;
              params.passOrtRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Passkopie') {
              params.passkopieBwRequired = data.valueDOMEdited;
              params.passkopieBwRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Passfarbkopie') {
              params.passkopieColorRequired = data.valueDOMEdited;
              params.passkopieColorRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Passfoto') {
              params.passfotoRequired = data.valueDOMEdited;
              params.passfotoRequiredFlug = data.valueFOMEdited;
            } else if (data.name === 'Versicherungsnachweis') {
              params.versicherungsnachweisRequired = data.valueDOMEdited;
            } else if (data.name === 'Verzichtserklärung') {
              params.verzichtserklaerungRequired = data.valueDOMEdited;
            } else if (data.name === 'LOI-Antrag') {
              params.loiAntragRequired = data.valueDOMEdited;
            } else if (data.name === 'Referenznr.-Antrag') {
              params.referenznummerAntragRequired = data.valueDOMEdited;
            } else if (data.name === 'Zum Buchungszeitpunkt benötigt') {
              params.zumBuchungszeitpunktBenoetigt = data.valueDOMEdited;
            }
          } else if (data.type === 'textField') {
            if (data.name === 'Weitergabe an CM') {
              params.weitergabeAnCm = data.valueEdited;
            } else if (data.name === 'Blank Kürzel') {
              params.blankKuerzel = data.valueEdited;
            } else if (data.name === 'Grund') {
              params.grund = data.valueEdited;
            }
          }
        });
      });

      // API-Aufruf mit dem korrekt formatierten Payload
      apiService
        .put(`Reise/${reisekuerzel}/konfiguration/${params.id}`, params)
        .then(response => {
          if (response.data.result.isSuccess && response.data.result.value != null) {
            const updatedData = response.data.result.value;
            this.toast('Erfolgreich bearbeitet.');

            // Weise die neuen Werte der upgedateten Saison zu
            this.konfigurationen = this.konfigurationen.map(saison => {
              if (updatedData.gueltigAb != saison.gueltigAb) return saison;

              return updatedData;
            });

            // Update die edited saison
            this.setEditedKonfiguration();
          }
        })
        .catch(error => {
          console.error('API-Fehler: ', error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async deleteSaison(hide) {
      if (!isSameDay(new Date(this.activeKonfiguration.gueltigAb), new Date('0001-01-01'))) {
        // Hole die ID der aktiven Konfiguration
        const { id: configId } = this.activeKonfiguration;

        const { reisekuerzel } = this.reise;
        this.isModalLoading = true;
        apiService.delete(`Reise/${reisekuerzel}/konfiguration/${configId}`).then(response => {
          // const result = response.data.result.value;
          this.toast('Erfolgreich gelöscht.');

          // Konfigurationen -> Lösche die Saison ( filtern die raus )
          this.konfigurationen = this.konfigurationen.filter(
            konfiguration => konfiguration.gueltigAb != this.activeKonfiguration.gueltigAb
          );
          // Setze das aktive gültig ab auf die jetzt neuerdings oberste saison
          this.activeSaisonGueltigAb = this.sortSaisons[0]?.gueltigAb;
          this.setEditedKonfiguration();

          this.isModalLoading = false;
          this.isLoading = false;

          hide();
        });
      }
    },
    addNewSaison(hide) {
      if (this.gueltigAb) {
        const { reisekuerzel } = this.reise;
        this.isModalLoading = true;
        const newFormattedGueltigAb = format(this.gueltigAb, 'yyyy-MM-dd');
        const neededFields = [
          'geburtsdatumRequired',
          'passNummerRequired',
          'passartRequired',
          'nationalitaetRequired',
          'geschlechtRequired',
          'passAusstellungsdatumRequired',
          'passGueltigkeitsdatumRequired',
          'passLandRequired',
          'passOrtRequired',
          'geburtsortRequired',
          'geburtslandRequired',
          'passkopieBwRequired',
          'passkopieColorRequired',
          'passfotoRequired',
          'geburtsdatumRequiredFlug',
          'passNummerRequiredFlug',
          'passartRequiredFlug',
          'nationalitaetRequiredFlug',
          'geschlechtRequiredFlug',
          'passAusstellungsdatumRequiredFlug',
          'passGueltigkeitsdatumRequiredFlug',
          'passLandRequiredFlug',
          'passOrtRequiredFlug',
          'geburtsortRequiredFlug',
          'geburtslandRequiredFlug',
          'passkopieBwRequiredFlug',
          'passkopieColorRequiredFlug',
          'passfotoRequiredFlug',
          'versicherungsnachweisRequired',
          'verzichtserklaerungRequired',
          'loiAntragRequired',
          'referenznummerAntragRequired',
          'zumBuchungszeitpunktBenoetigt',
          'weitergabeAnCm',
          'blankKuerzel',
          'grund',
          'gueltigAb',
        ];

        const saisonParams = neededFields.reduce((newObject, currentKey) => {
          if (currentKey === 'gueltigAb') {
            newObject[currentKey] = newFormattedGueltigAb;
            return newObject;
          }
          newObject[currentKey] = this.activeKonfiguration[currentKey];
          return newObject;
        }, {});
        apiService.post(`Reise/${reisekuerzel}/konfiguration`, saisonParams).then(response => {
          // this.setEditedKonfiguration();
          this.toast('Erfolgreich erstellt.');
          const newSaison = response.data.result.value;

          this.konfigurationen = [...this.konfigurationen, newSaison];
          this.setActiveSaisonGueltigAb(newSaison.gueltigAb);
          this.setEditedKonfiguration();

          this.isLoading = false;
          this.gueltigAb = null;
          this.isModalLoading = false;
          hide();
        });
      }
    },
    resetAndCloseModal(hide) {
      this.gueltigAb = null;
      hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';
.plus-sign {
  height: 24px;
  width: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.widthEditField {
  width: 225px;
}

:deep(.custom-checkbox-DOM .custom-control-input ~ .custom-control-label::before) {
  border-color: #1bc5bd;
}
:deep(.custom-checkbox-DOM .custom-control-input:checked ~ .custom-control-label::before) {
  border-color: #1bc5bd;
  background-color: #1bc5bd;
}
:deep(.custom-checkbox-FOM .custom-control-input ~ .custom-control-label::before) {
  border-color: #ffa800;
}
:deep(.custom-checkbox-FOM .custom-control-input:checked ~ .custom-control-label::before) {
  border-color: #ffa800;
  background-color: #ffa800;
}

:deep(
    .custom-checkbox.b-custom-control-lg .custom-control-label::after,
    .input-group-lg .custom-checkbox .custom-control-label::after
  ) {
  width: 2.3rem;
  height: 2.3rem;
  background-size: 50% 50%;
}

:deep(
    .custom-checkbox.b-custom-control-lg .custom-control-label::before,
    .input-group-lg .custom-checkbox .custom-control-label::before
  ) {
  width: 2.2rem;
  height: 2.3rem;
  border-radius: 0.3rem;
}
</style>
